:root {
  --surface: #1f2336;

  --static-white: #ffffff; //static white stays white on dark mode, to be used on button and other color elements
  --static-black: #121242; //static black stays black on dark mode, to be used on button and other color elements

  --primary-font-family: "Poppins", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;

  --primary: #7950ed;
  --secondary: #a787ff;
  --dark-purple: #6032ea; /** @todo rename */
  --raised-button-fill: #6032ea;
  --mdc-theme-primary: var(--primary);
  --mdc-theme-secondary: (--dark-purple);
  --mdc-typography-body2-font-family: var(--primary-font-family);
  --mdc-typography-subtitle2-font-family: var(
    --mdc-typography-body2-font-family
  );

  --gray-base: #ffffff;
  --gray-10: #b7bac7;
  --gray-20: #a3a8b8;
  --gray-30: #80869d;
  --gray-40: #686f8c;
  --gray-50: #515873;
  --gray-60: #444b66;
  --gray-70: #373d57;
  --gray-80: #2c3249;
  --gray-90: #22273c;
  --gray-full: #15192c;

  --border-color: var(--gray-80);

  --theme-yellow: #f5b82e;
  --theme-red: #f85b6e;
  --theme-light-red: #ff6e6e;
  --theme-pink: #f685a1;
  --theme-turquoise: #53dbee;
  --theme-green: #8dd9b9;
  --theme-blue: #20a4f3;
  --theme-orange: #f6aa50;

  --color-positive: #31c587;
  --color-negative: var(--theme-red);
  --theme-gradient: linear-gradient(45deg, #6c37ff 0%, #23c4da 100%);

  --tooltip-background: var(--gray-60);

  --panel-background: var(--gray-80);
  --panel-hover-background: var(--gray-70);

  --menu-background-color: var(--gray-90);

  --toggle-track-background: #e3ddf6;

  --form-horizontal-width: 700px;
  --form-horizontal-label-width: 300px;

  //font sized
  --normal-font-size: 14px;
  --tag-font-size: 12px;
  --subtle-font-size: 10px;
  --h1-font-size: 32px;
  --h2-font-size: 24px;
  --h3-font-size: 18px;
  --h4-font-size: 14px;

  //todo: check if the below is used and remove or consolidate
  --large-icon-font-size: 18px;
  --title3-font-size: 18px;
  --title4-font-size: 24px;
  --icon-font-size: 16px;
  --title5-font-size: 14px;
  --text-field-label-font-size: 10px;
  --text-field-label-horizontal-font-size: var(--normal-font-size);

  --mdc-typography-body2-font-size: var(--normal-font-size);

  --icon-size-xxsmall: 16px;
  --icon-size-xsmall: 20px;
  --icon-size-small: 24px;
  --icon-size-medium: 32px;
  --icon-size-large: 44px;
  --icon-size-xlarge: 54px;
  --icon-size-xxlarge: 64px;

  --icon-spacing: 6px;
  --default-spacing: 12px;
  --default-spacing-small: 8px;
  --medium-spacing: 14px;
  --large-spacing: 16px;
  --double-spacing: 24px;
  --triple-spacing: 36px;
  --form-field-spacing: 16px;

  --user-badge-spacing: 6px;

  --form-field-long: 320px;
  --form-field-normal: 200px;
  --textarea-height-small: 60px;

  --field-height: 34px;
  --textarea-height: 130px;

  --modal-min-width: 470px;
  --button-min-width: 16px;

  --header-height: 50px;
  --footer-height: 35px;
  --header-spacing: 50px;
  --menu-width: var(--header-height);
  --menu-width-expanded: 230px;
  --menu-item-width: 32px;
  --menu-item-height: var(--menu-item-width);
  --small-menu-item-width: 20px;
  --small-menu-item-height: var(--small-menu-item-width);
  --menu-expand-animation-duration: 0.2s;

  --menu-item-color: #a3a8b8;
  --menu-item-active-background: #515873;
  --menu-item-active-color: #ffffff;

  --changes-menu-width: 261px;
  --application-card-width: 390px;
  --application-card-height: 210px;

  --default-border-radius: 4px;
  --xxlarge-border-radius: 35px;
  --scrollbar-radius: 20px;
  --form-elements-border-radius: var(--default-border-radius);
  --form-permission-type-radius: var(--default-border-radius);

  --default-page-padding: calc(var(--default-spacing-small) * 3);
  --side-bar-width: 440px;
  --side-bar-large-width: 640px;
  --side-bar-min-width: 30vw;

  --default-button-height: 28px;
  --default-button-radius: 4px;

  --medium-button-height: 28px;
  --medium-button-radius: var(--default-button-radius);

  --time-since-height: 24px;

  --default-box-shadow: 0px 7px 10px rgba(56, 28, 138, 0.06);
  --modal-box-shadow: 0px 7px 10px rgba(56, 28, 138, 0.06);
  --hover-box-shadow: 0 0 6px rgba(63, 37, 135, 0.1);
  --menu-box-shadow: 4px 0 12px rgba(79, 65, 116, 0.08);

  --page-content-background: #1f2336;
  --page-content-tab-background: var(--gray-full);

  --fixed-menu-panel-width: 300px;
  --fixed-menu-panel-max-width: 300px;
  --app-footer-height: 50px;

  --theme-turquoise-transparent: rgba(65, 202, 221, 0.1);
  --theme-light-red-transparent: rgba(248, 91, 110, 0.1);

  --page-content-width-default: 1300px;
}
