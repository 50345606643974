.amp-theme-dark {
  --welcome-page-stripe-background: var(--gray-full);

  --action-log-background-color: var(--gray-full);
  --action-log-header-color: var(--gray-base);
  --action-log-step-color: var(--gray-base);

  --image-roles: url("../assets/images/mono/roles-dark.svg");
  --image-entities: url("../assets/images/mono/entities-dark.svg");
  --image-sync-github: url("../assets/images/mono/sync-github-dark.svg");
  --image-commit: url("../assets/images/mono/commit-dark.svg");
  --image-no-changes: url("../assets/images/mono/no-changes-dark.svg");
  --image-add-resource: url("../assets/images/mono/add-resource-dark.svg");
  --image-relations: url("../assets/images/mono/relations-dark.svg");
  --image-import-excel: url("../assets/images/mono/import-excel-dark.svg");
  --image-drop-excel: url("../assets/images/mono/drop-excel-dark.svg");
  --image-my-resources: url("../assets/images/mono/my-resources-dark.svg");
  --image-generating: url("../assets/images/mono/generating-dark.svg");
  --image-create-service-wizard: url("../assets/images/mono/service-wizard-create.svg");
  --image-commit-empty-state: url("../assets/images/mono/commit-empty-state-dark.svg");
  --image-code-view-empty-state: url("../assets/images/mono/code-view-empty-state-dark.svg");
  --image-message-broker-empty-state: url("../assets/images/mono/message-broker-empty-state-dark.svg");
  --plugin-installation-empty: url("../assets/images/mono/plugin-installation-empty-dark.svg");
  --image-import-prisma-schema: url("../assets/images/mono/import-prisma-schema-dark.svg");
  --image-import-prisma: url("../assets/images/mono/import-prisma-dark.svg");

  --image-dotnet: url("../assets/images/tech-stack/dotnet.svg");
  --image-nodejs: url("../assets/images/tech-stack/nodejs.svg");

  --diffViewerBackground: var(--gray-full);
  --diffViewerColor: var(--gray-base);
  --addedBackground: #1b3b3e;
  --addedColor: var(--gray-base);
  --removedBackground: #3f2033;
  --removedColor: var(--gray-base);
  --wordAddedBackground: #288e6a;
  --wordRemovedBackground: #a53145;
  --addedGutterBackground: #205e50;
  --removedGutterBackground: #6a273b;
  --gutterBackground: var(--gray-80);
  --gutterBackgroundDark: var(--gray-full);
  --highlightBackground: var(--gray-full);
  --highlightGutterBackground: var(--gray-full);
  --codeFoldGutterBackground: var(--gray-80);
  --codeFoldBackground: var(--gray-90);
  --emptyLineBackground: var(--gray-90);
  --gutterColor: var(--gray-base);
  --addedGutterColor: var(--gray-base);
  --removedGutterColor: var(--gray-base);
  --codeFoldContentColor: var(--gray-base);
  --diffViewerTitleBackground: var(--gray-full);
  --diffViewerTitleColor: var(--gray-base);
  --diffViewerTitleBorderColor: var(--gray-full);
  --screenResolutionMessageColor: var(--gray-base);
}
