@import "./style/dark-css-variables.css";
@import "./style/index.scss";

:root {
  --assistant-background: var(--surface);
  --assistant-message-background: var(--gray-full);
  --assistant-message-color: var(--gray-base);
  --assistant-title-color: var(--gray-base);
  --assistant-role-color: var(--gray-base);
  --assistant-handle-background: var(--theme-gradient);

  --assistant-box-shadow: 5px 0px 5px rgba(56, 28, 138, 0.06);
}

body {
  color: var(--gray-base);
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: subpixel-antialiased;
  background-color: var(--surface);
}

#root {
  height: 100vh;
  overflow-y: hidden;
}

.text-medium {
  font-weight: 500;
}

//disable outline on react-hotkeys wrapper
.hotkeys-wrapper {
  outline: 0;
  width: 100%;
  height: 100%;
}

div[tabindex="-1"]:focus,
button:focus,
summary:focus,
a:focus {
  outline: 0 !important;
}

.amp-page main {
  min-width: 500;
}

@media only screen and (max-width: 900px) {
  .amp-page main {
    min-width: calc(900px - var(--menu-width));
  }
}

::-webkit-scrollbar {
  width: var(--default-spacing);
}
::-webkit-scrollbar-button {
  background-color: var(--gray-70);
}
::-webkit-scrollbar-track {
  background-color: var(--gray-80);
}

::-webkit-scrollbar-thumb {
  background-color: var(--gray-40);
}
